import React from "react"
import Subscribe from "../Blog/Subscribe/subscribe"

const stickyBox = ({ sticky, setStickyBoxToFalse }) => (
  <div className={sticky ? "sticky-box" : "no-sticky-box"}>
    <div className="sticky-subscriber">
      <h1 className="text-medium text-dark text-weight-light text-center  mr-1">
      Subscribe to receive the free weekly article
      </h1>
      <Subscribe setSticky={setStickyBoxToFalse} buttonColor="btn-email-dark" />
    </div>
    <button className="btn-close-box" onClick={setStickyBoxToFalse}>
      X
    </button>
  </div>
)

export default stickyBox
