import React, { Fragment } from "react"

import { OneCoffee, TwoCoffee } from "./read-coffee"

const headPost = ({ published, date, read, readText }) => (
  <Fragment>
    <span className="text-tertiary text-xsmall mr-1">
     { published ? published + date: ' ' + date }
    </span>
    <span className="text-tertiary text-xsmall mr-1">
      {read <= 7 ? <OneCoffee /> : <TwoCoffee />} {` ${read}`} min {readText}
    </span>
  </Fragment>
)

export default headPost
