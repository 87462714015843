import React from 'react'

const Donate = () => (
 <div className="bmc-holder">
    <a className="bmc-button" target="_blank" href="https://www.buymeacoffee.com/koIR0wM" rel="noopener noreferrer">
    <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="coffee" />
    <span>
         Buy me a coffee
    </span>
    </a>
 </div>
)

export default Donate