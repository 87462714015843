import React from "react"
import { Link } from "gatsby"
import favicon from '../../assets/images/icon.png'
import DarkLight from "../darkLight"

const NavBar = ({ title }) => {
  return (
    <nav className="navbar">
      <Link to="/">
        <div className="tags-flex">
          <img src={favicon} alt="Ibrahiman" className="avatar" />
          <div className="site-title-holder">
            <h2 className="text-large text-dark">{title}</h2>
          </div>
        </div>
      </Link>
      <DarkLight />
    </nav>
  )
}
export default NavBar
