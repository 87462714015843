import React from "react"
import SocialLink from "./social-link"

const contact = ({ links, siteUrl }) => (
  <div className="m-1">
    <a
      target="_blank"
      className="badge-icon"
      rel="noopener noreferrer"
      href={siteUrl + "/rss.xml"}
    >
    <i className="fa fa-wifi"></i>
      rss
    </a>
    {links.map(({ name, link }) => (
      <SocialLink key={link} name={name} link={link} />
    ))}
  </div>
)

export default contact
