import React from "react"

const subscriberForm = ({
  handleFormSubmit,
  handleEmailChange,
  buttonColor,
  buttonText
}) => (
  <form
    id="email-capture"
    method="post"
    noValidate
    className="tags-flex"
    onSubmit={handleFormSubmit}
  >
    <input
      aria-label="Subscription Form"
      type="email"
      className="text-xsmall"
      placeholder="you@example.com"
      onChange={handleEmailChange}
      required
    />
    <button className={`btn-email text-small ${buttonColor}`} type="submit">
      <i className="fas fa-envelope"></i> {buttonText}
    </button>
  </form>
)

export default subscriberForm
