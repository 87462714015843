import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import SubscriberForm from "./subscriber-form"
import SuccessMessage from "./success-message"
import ErrorMessage from "./error-message"

const Subscribe = ({ children, buttonColor, setSticky }) => {
  const [email, setEmail] = useState("")
  const [buttonText, setButtonText] = useState("Send")
  const [message, setMessage] = useState({ status: "", msg: "" })

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
      .then(result => {
        if (result.result !== `success`) {
          setMessage({ status: `error`, msg: result.msg })
          setButtonText("Send")
        } else {
          setMessage({ status: `success`, msg: result.msg })
          setSticky()
        }
      })
      .catch(err => {
        setMessage({ status: `error`, msg: err })
        setButtonText("Send")
      })
  }

  const handleFormSubmit = e => {
    e.preventDefault()

    if (!email) {
      setMessage({
        status: `error`,
        msg: "Your address e-mail is not valid",
      })
    } else {
      setMessage({
        status: `sending`,
        msg: null,
      })
      setButtonText("Sending...")
      postEmailToMailchimp(email, {
        pathname: document.location.pathname,
      })
    }
  }

  return (
    <div>
      {message.status === `success` ? (
        <SuccessMessage />
      ) : (
        <div>
          {children}
          <SubscriberForm
            handleFormSubmit={handleFormSubmit}
            handleEmailChange={handleEmailChange}
            buttonColor={buttonColor}
            buttonText={buttonText}
          />
          {message.status === `error` && <ErrorMessage message={message} />}
        </div>
      )}
    </div>
  )
}

export default Subscribe
