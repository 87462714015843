import React from "react"

const errorMessage = ({ message: { msg } }) => (
  <p
    className="text-center text-error text-xsmall"
    dangerouslySetInnerHTML={{ __html: msg }}
  ></p>
)

export default errorMessage
