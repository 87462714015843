import React from "react"

const tags = ({ tags }) => (
  <div style={{padding:'0'}}>
    {tags.map(tag => (
      <span key={tag} className={`badge badge-${tag}`}>
        #{tag}
      </span>
    ))}
  </div>
)

export default tags
