import React, { useState, useEffect } from "react"
import { Waypoint } from "react-waypoint"
import PropTypes from "prop-types"
import { getCurrentLangKey } from "ptz-i18n"
import { useStaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl"
import "intl"

import "../assets/scss/main.scss"
import NavBar from "../components/NavBar/navbar"
import StickyBox from "../components/NavBar/sticky-box"
import Contact from "../components/Blog/Contact/contact"

const Layout = ({ children, location, i18nMessages }) => {
  const [sticky, setSticky] = useState(false)
  const [isStickyShown, setIsStickyShown] = useState(false)
  useEffect(() => {
    if (!isStickyShown) {
      const response = JSON.parse(localStorage.getItem("stickyBox"))
      if (response) setIsStickyShown(response)
    }
  }, [isStickyShown])
  
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            social_links {
              name
              link
            }
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `
  )

  const {
    siteUrl,
    title,
    social_links,
    languages: { langs, defaultLangKey },
  } = data.site.siteMetadata
  const url = location.pathname
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const handleStickyBox = () => {
    localStorage.setItem("stickyBox", true)
    setIsStickyShown(true)
  }
  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      {isStickyShown ? null : (
        <>
          <Waypoint onEnter={()=> setSticky(false)} onLeave={() => setSticky(true)} />
          <StickyBox
            setStickyBoxToFalse={handleStickyBox}
            social_links={social_links}
            sticky={sticky}
          />
        </>
      )}
      <main className="container-layout">
      <NavBar title={title} />
        {children}
      </main>
      <footer className="foot flex footer">
        <Contact links={social_links} siteUrl={siteUrl} />
        <p className="text-small text-center">
          &copy; 2019 - {new Date().getFullYear()}, Built with{" "}
          <span role='img' aria-label="love">❤️</span> to code
        </p>
      </footer>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
