import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

const darkLight = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <label className="switcher">
        <input
          className="container_toggle"
          id="switch"
          name="mode"
          type="checkbox"
          onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
          checked={theme === "dark"}
        />
      </label>
    )}
  </ThemeToggler>
)

export default darkLight
