import React from "react"

const socialLink = ({ name, link }) => (
  <a
    target="_blank"
    className="badge-icon"
    rel="noopener noreferrer"
    href={link}
  >
   {name}
  </a>
)
export default socialLink
